'use client'
import { useEffect } from 'react';
import Image from "next/image";
import Icon from "../../public/assets/1mcreativedark.svg";
import { LuExternalLink } from "react-icons/lu";
import { logEvent } from '@/utils/analytics';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  
  const handleContactClick = () => {
    logEvent('contact_click', {
      button_name: 'mcreative_contact',
      location: 'footer',
      destination: 'whatsapp',
      device_type: window.innerWidth < 768 ? 'mobile' : 'desktop'
    });

    const message = encodeURIComponent("Olá, gostaria de mais informações sobre seus serviços.");
    window.open(`https://api.whatsapp.com/send?phone=5555991955547&text=${message}`, '_blank');
  };

  return (
    <footer className="bg-background w-auto">
      <div className="container mx-auto px-4 py-8">
        <div className="flex flex-col md:flex-row justify-between items-center gap-4">
          <div className="text-gray-300 text-sm text-center">
            <p>&copy; {currentYear} HM Estética Avançada</p>
            <p>Todos os direitos reservados</p>
          </div>
          
          <div className="flex items-center gap-2 place-items-center">
            <span className="text-gray-300 text-sm">Desenvolvido por</span>
            <button
              onClick={handleContactClick}
              className="flex items-center gap-2 transition-transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200 rounded-lg p-2"
            >
              <div className="flex items-center gap-2">
                <Image 
                  src={Icon} 
                  alt="Logo MCreative Web Design"
                  className="w-full h-16 object-contain"
                  priority
                />
                <LuExternalLink className="w-4 h-4 text-gray-400" />
              </div>
            </button>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;