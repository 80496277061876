'use client';

import { Link } from 'next-view-transitions';
import { logEvent } from '@/utils/analytics';
import type { ComponentProps } from 'react';

interface TrackedTransitionLinkProps extends ComponentProps<typeof Link> {
  category?: string;
  label?: string;
}

export default function TrackedTransitionLink({
  href,
  children,
  className,
  category = 'link_click',
  label,
  onClick,
  ...props
}: TrackedTransitionLinkProps) {
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    logEvent('link_click', {
      category: category,
      link_url: href.toString(),
      link_text: label || (typeof children === 'string' ? children : undefined),
      link_id: props.id,
    });

    if (onClick) {
      onClick(e);
    }
  };
  return (
    <Link 
      href={href} 
      className={className} 
      onClick={handleClick}
      {...props}
    >
      {children}
    </Link>
  );
}