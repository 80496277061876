'use client'

import { motion } from 'framer-motion';
import Link from "next/link"
import Image from "next/image"
import MapPlace from "../../public/assets/map.png"
import Logo from "../../public/Logo.png"
import { FaInstagram, FaWhatsapp } from "react-icons/fa6"
import Button from "./Button"
import { LuMail, LuMapPin, LuPhone } from "react-icons/lu"
import { logEvent } from '@/utils/analytics'

const fadeInUp = {
  initial: { opacity: 0, y: 20 },
  whileInView: { opacity: 1, y: 0 },
  viewport: { once: true },
  transition: { duration: 0.6, ease: "easeOut" }
};

const staggerChildren = {
  initial: { opacity: 0 },
  whileInView: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2
    }
  },
  viewport: { once: true }
};

const contactLinks = [
  {
    icon: <FaInstagram className="w-6 h-6 text-textbrand"/>,
    text: "@hermaniomourao",
    href: "https://www.instagram.com/hermaniomourao/?igsh=MTJwbDhtZGRicXJxdg%3D%3D&utm_source=qr",
    isLink: true,
    name: 'instagram'
  },
  {
    icon: <LuPhone className="w-6 h-6 text-textbrand"/>,
    text: "(51) 3226-2654",
    isLink: false,
    name: 'phone'
  },
  {
    icon: <FaWhatsapp className="w-6 h-6 text-textbrand"/>,
    text: "(51) 9 9550-2553",
    href: "https://api.whatsapp.com/send?phone=5551995502553&text=Ol%C3%A1!%20Gostaria%20de%20mais%20informa%C3%A7%C3%B5es%20sobre%20agendamento%20de%20consulta.",
    isLink: true,
    name: 'whatsapp'
  }
]

export default function Contact() {
  const handleContactClick = (name: string, href?: string) => {
    logEvent('contact_click', {
      contact_type: name,
      location: 'contact_section',
      device_type: window.innerWidth < 768 ? 'mobile' : 'desktop'
    });

    if (href) {
      window.open(href, '_blank');
    }
  };

  const handleLocationClick = () => {
    logEvent('location_click', {
      location: 'contact_section',
      device_type: window.innerWidth < 768 ? 'mobile' : 'desktop'
    });
    window.open("https://www.google.com/maps/place/HM+Est%C3%A9tica+Avan%C3%A7ada/@-30.0428914,-51.1880553,16z", '_blank');
  };

  const handleEmailClick = () => {
    logEvent('email_click', {
      location: 'contact_section',
      device_type: window.innerWidth < 768 ? 'mobile' : 'desktop'
    });
  };

  return (
    <motion.section 
      id="contato" 
      className="mx-2 flex flex-col items-center bg-gradient-to-b from-gray-200 via-[#D5D1CE] to-[#C5C1BE] rounded-xl xl:mx-10 shadow-lg"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 0.8 }}
    >
      <motion.h1 
        className="my-8 font-display text-6xl md:text-8xl text-textbrand drop-shadow-lg"
        {...fadeInUp}
      >
        Contato
      </motion.h1>

      <motion.div 
        className="w-full max-w-4xl px-4"
        variants={staggerChildren}
        initial="initial"
        whileInView="whileInView"
      >
        <motion.ul className="font-semibold flex flex-col items-center lg:flex-row lg:justify-center lg:gap-10 mb-2 text-black">
          {contactLinks.map((contact, index) => (
            <motion.li 
              key={index} 
              className="pb-4"
              variants={fadeInUp}
            >
              {contact.isLink ? (
                <button
                  onClick={() => handleContactClick(contact.name, contact.href)}
                  className="flex items-center gap-2 text-xl lg:text-2xl hover:text-textbrand transition-colors duration-200 group"
                >
                  <motion.div
                    whileHover={{ scale: 1.1 }}
                    transition={{ duration: 0.2 }}
                    className="flex items-center gap-2"
                  >
                    {contact.icon}
                    <span>{contact.text}</span>
                  </motion.div>
                </button>
              ) : (
                <motion.div 
                  className="flex items-center gap-2 text-xl lg:text-2xl"
                  whileHover={{ scale: 1.05 }}
                  transition={{ duration: 0.2 }}
                >
                  {contact.icon}
                  <span>{contact.text}</span>
                </motion.div>
              )}
            </motion.li>
          ))}
        </motion.ul>

        <motion.div 
          className="flex flex-col items-center gap-6 mb-8"
          variants={fadeInUp}
        >
          <motion.button
            onClick={handleEmailClick}
            className="flex items-center gap-2 text-lg lg:text-2xl font-semibold text-black hover:text-textbrand transition-colors duration-200"
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.2 }}
          >
            <LuMail className="w-6 h-6 text-textbrand"/>
            <span>hm.esteticaavancada@gmail.com</span>
          </motion.button>
          <motion.div
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.2 }}
          >
            <Button title="agendar consulta"/>
          </motion.div>
        </motion.div>
      </motion.div>

      <motion.div 
        className="w-full grid grid-cols-1 lg:grid-cols-2 gap-8 items-center pb-8"
        variants={staggerChildren}
        initial="initial"
        whileInView="whileInView"
      >
        <motion.div 
          className="flex flex-col items-center px-4"
          variants={fadeInUp}
        >
          <h2 className="my-4 font-display text-5xl md:text-6xl text-textbrand drop-shadow-lg">
            Localização
          </h2>
          
          <button
            onClick={handleLocationClick}
            className="group"
          >
            <motion.div 
              className="flex gap-2 items-center mb-4 hover:text-textbrand transition-colors duration-200 text-black"
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.2 }}
            >
              <LuMapPin className="w-6 h-6 text-textbrand"/>
              <strong className="text-lg">
                Av. Itaqui, 72/504 - Petrópolis,<br/>
                Porto Alegre - RS, 90460-140
              </strong>
            </motion.div>
            <motion.div 
              className="overflow-hidden rounded-lg shadow-lg group-hover:shadow-xl transition-shadow duration-200"
              whileHover={{ scale: 1.02 }}
              transition={{ duration: 0.3 }}
            >
              <Image 
                src={MapPlace} 
                alt="Localização da HM Estética Avançada no mapa"
                height={350}
                className="w-full object-cover transition-transform duration-200 group-hover:scale-105"
                sizes="(max-width: 768px) 100vw, 50vw"
                priority
              />
            </motion.div>
          </button>
        </motion.div>

        <motion.div 
          className="flex justify-center px-4"
          variants={fadeInUp}
        >
          <motion.div
            whileHover={{ scale: 1.05 }}
            transition={{ duration: 0.3 }}
          >
            <Image 
              src={Logo} 
              alt="Logo HM Estética Avançada"
              height={600}
              className="w-auto max-w-full"
              sizes="(max-width: 768px) 100vw, 50vw"
            />
          </motion.div>
        </motion.div>
      </motion.div>
    </motion.section>
  )
}