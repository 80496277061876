'use client';

import Script from 'next/script';

interface GoogleAnalyticsScriptProps {
  GA_MEASUREMENT_ID: string;
}

export function GoogleAnalyticsScript({ GA_MEASUREMENT_ID }: GoogleAnalyticsScriptProps) {
  return (
    <>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`}
        strategy="afterInteractive"
        onLoad={() => {
          console.log('Script do GA carregado com sucesso');
        }}
        onError={(e) => {
          console.error('Erro ao carregar script do GA:', e);
        }}
      />
      <Script
        id="google-analytics"
        strategy="afterInteractive"
        onLoad={() => {
          console.log('Configuração do GA carregada com sucesso');
        }}
      >
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${GA_MEASUREMENT_ID}', {
            'anonymize_ip': true
          });
        `}
      </Script>
    </>
  );
}