'use client';

import { useState, useEffect } from 'react';

interface ConsentBannerProps {
  onAccept: () => void;
  onDecline: () => void;
}

export function ConsentBanner({ onAccept, onDecline }: ConsentBannerProps) {
  return (
    <div className="fixed bottom-0 left-0 right-0 p-4 shadow-lg border-t border-textbrand bg-gray-900">
      <div className="max-w-4xl mx-auto">
        <p className="text-sm text-gray-200 mb-4">
          Utilizamos cookies e tecnologias similares para melhorar sua experiência. 
          Você aceita o uso de Google Analytics para nos ajudar a entender como você usa nosso site?
        </p>
        <div className="flex gap-4 justify-end">
          <button type='button'
            onClick={onDecline}
            className="px-4 py-2 text-sm text-gray-100 bg-gray-500 hover:bg-gray-100 rounded"
          >
            Recusar
          </button>
          <button type='button'
            onClick={onAccept}
            className="px-4 py-2 text-sm text-white bg-textbrand hover:bg-blue-700 rounded"
          >
            Aceitar
          </button>
        </div>
      </div>
    </div>
  );
}