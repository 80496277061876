'use client'
import Image from 'next/image'
import GifLogo from '../../public/assets/giflogo.gif'
import { CgClose } from 'react-icons/cg'
import * as Collapsible from '@radix-ui/react-collapsible'
import { useCallback, useState } from 'react'
import { LuMenu } from 'react-icons/lu'
import TrackedTransitionLink from './TrackedTransitionLink'

const MENU_LINKS = [
  { href: '/#tratamentos', label: 'tratamentos', category: 'navigation' },
  { href: '/drhermaniomourao', label: 'dr. hermanio mourão', category: 'navigation' },
  { href: '/sobre', label: 'Quem Somos', category: 'navigation' },
  { href: '/cursos', label: 'Nossos cursos', category: 'navigation' },
  { href: '/cursos#paciente', label: 'seja paciente modelo', category: 'navigation' },
  { href: '#contato', label: 'Contato', category: 'contact' }
] as const

export default function Header() {
  const [open, setOpen] = useState(false)
  
  const handleLinkClick = useCallback(() => {
    setOpen(false)
  }, [])

  return (
    <header className="gap-8 p-6 flex flex-row bg-black justify-between lg:items-center font-sans shadow-black shadow-lg">
      <TrackedTransitionLink 
        href="/" 
        aria-label="Ir para página inicial"
        category="navigation"
        label="Logo Home"
      >
        <video autoPlay loop muted playsInline className="lg:pl-8 w-[150px] h-[150px] object-contain">
          <source src="/assets/giflogo.webm" type="video/webm" />
           <Image
            src={GifLogo}
            width={150}
            height={200}
            quality={80}
            priority
            alt="Logo"
            />
          </video>
      </TrackedTransitionLink>

      <Collapsible.Root 
        open={open} 
        onOpenChange={setOpen}
        className="flex justify-between text-textcolor font-semibold items-center"
      >
        <Collapsible.Trigger 
          asChild 
          className="lg:hidden"
        >
          <button
            type="button"
            className="p-2 hover:bg-zinc-800 rounded-md transition-colors"
            aria-label={open ? 'Fechar menu' : 'Abrir menu'}
          >
            {open ? (
              <CgClose className="text-textbrand w-10 h-10" />
            ) : (
              <LuMenu className="text-textbrand w-10 h-10" />
            )}
          </button>
        </Collapsible.Trigger>

        <Collapsible.Content
          forceMount
          className="data-[state=closed]:hidden lg:data-[state=closed]:flex"
        >
          <nav>
            <ul className="lg:p-2 p-8 uppercase fixed top-32 w-full right-0 z-20 bg-black lg:flex lg:flex-row lg:top-0 lg:right-4 lg:relative lg:gap-4 text-base">
              {MENU_LINKS.map(({ href, label, category }) => (
                <li key={href} className="pb-4">
                  <TrackedTransitionLink
                    href={href}
                    onClick={handleLinkClick}
                    category={category}
                    label={`Menu: ${label}`}
                    prefetch={true}
                    className="pb-1 cursor-pointer bg-gradient-to-r from-black via-textbrand to-black bg-left-bottom bg-[length:0%_1px] bg-no-repeat transition-all duration-700 ease-in-out hover:bg-[length:100%_1px] hover:text-textbrand"
                  >
                    <span>{label}</span>
                  </TrackedTransitionLink>
                </li>
              ))}
            </ul>
          </nav>
        </Collapsible.Content>
      </Collapsible.Root>
    </header>
  )
}