'use client';

import { useState, useEffect } from 'react';
import { ConsentBanner } from './ConsentBanner';
import { GoogleAnalyticsScript } from './GoogleAnalyticsScript';

export default function GoogleAnalytics({ GA_MEASUREMENT_ID }: { GA_MEASUREMENT_ID?: string }) {
  const [showBanner, setShowBanner] = useState(false);
  const [hasConsent, setHasConsent] = useState<boolean | null>(null);
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
    const storedConsent = localStorage.getItem('analyticsConsent');
    setHasConsent(storedConsent === 'true');
    setShowBanner(storedConsent === null);
  }, []);

  if (!GA_MEASUREMENT_ID) {
    console.error('GA_MEASUREMENT_ID não está definido');
    return null;
  }

  if (!isClient) {
    return null;
  }

  const handleAccept = () => {
    localStorage.setItem('analyticsConsent', 'true');
    setHasConsent(true);
    setShowBanner(false);
  };

  const handleDecline = () => {
    localStorage.setItem('analyticsConsent', 'false');
    setHasConsent(false);
    setShowBanner(false);
  };

  return (
    <>
      {showBanner && (
        <ConsentBanner onAccept={handleAccept} onDecline={handleDecline} />
      )}
      {hasConsent && <GoogleAnalyticsScript GA_MEASUREMENT_ID={GA_MEASUREMENT_ID} />}
    </>
  );
}