import(/* webpackMode: "eager", webpackExports: ["ViewTransitions"] */ "D:\\final-hm\\hm-estetica\\node_modules\\next-view-transitions\\dist\\index.js");
;
import(/* webpackMode: "eager" */ "D:\\final-hm\\hm-estetica\\node_modules\\next\\dist\\client\\script.js");
;
import(/* webpackMode: "eager" */ "D:\\final-hm\\hm-estetica\\src\\app\\globals.css");
;
import(/* webpackMode: "eager" */ "D:\\final-hm\\hm-estetica\\node_modules\\next\\font\\google\\target.css?{\"path\":\"src\\\\lib\\\\fonts.tsx\",\"import\":\"Montserrat\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"500\",\"600\",\"700\"],\"variable\":\"--font-body\",\"display\":\"swap\",\"preload\":true}],\"variableName\":\"textFont\"}");
;
import(/* webpackMode: "eager" */ "D:\\final-hm\\hm-estetica\\node_modules\\next\\font\\google\\target.css?{\"path\":\"src\\\\lib\\\\fonts.tsx\",\"import\":\"Oooh_Baby\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":\"400\",\"variable\":\"--font-title\",\"display\":\"swap\",\"preload\":true}],\"variableName\":\"titleFont\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\final-hm\\hm-estetica\\src\\components\\Contact.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\final-hm\\hm-estetica\\src\\components\\Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\final-hm\\hm-estetica\\src\\components\\GoogleAnalytics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\final-hm\\hm-estetica\\src\\components\\Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\final-hm\\hm-estetica\\src\\components\\ScrollToTop.tsx");
